import React from "react";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { cartActions } from "../Store/CartSlice";
import { useDispatch, useSelector } from "react-redux";
import "../Assets/Styles/layouts/description.scss";
import { Alert } from "@mui/material";
// import ProductDescription from "../Layouts/ProductDescription";

function Description() {
  const productID = useSelector((state) => state.description.productDetails);
  const [productAdded, updateproductAdded] = useState(false);
  const [productOverAddHandler, updateproductOverAddHandler] = useState(false);
  const inCart = useSelector((state) => state.cart.itemList);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const addToCart = (title, id, price, imgUrl, maxBuyable) => {
    const hash = {};
    inCart?.map((item) => (item.id === id && item.quantity >= maxBuyable ? (hash[id] = true) : (hash[maxBuyable] = false)));
    if (hash[id] === true) return updateproductOverAddHandler(true);
    else {
      updateproductAdded(true);
      dispatch(
        cartActions?.addProdToCart({
          title,
          id,
          price,
          imgUrl,
        })
      );
    }
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      updateproductAdded(false);
      updateproductOverAddHandler(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, [productAdded, productOverAddHandler]);
  return (
    <section className="description-section">
      {productID?.map((product, key) => {
        return (
          <section key={product?.id}>
            <div className="back" onClick={() => navigate(-1)}>
              <p>Go back</p>
            </div>
            <div className="description-Wrapper">
              <div className="product-image">
                <img className="img-product" src={product?.imgUrl} alt="product-name" />
              </div>

              <div className="main-description-container">
                <div className="product-heading">
                  <h1 className="product-name">{product?.title}</h1>
                </div>
                <span className="description-title">Description: </span>
                <div className="description-container">
                  <p className="description">{product?.description} </p>
                </div>
                <span className="details-title">Details: </span>
                <div className="details-container">
                  <div className="detail">
                    <div className="abbrev">
                      <span>Quantity:</span>
                    </div>
                    <div className="answer">
                      <span>{product?.quantity}</span>
                    </div>
                  </div>

                  <div className="detail">
                    <div className="abbrev">Price:</div>
                    <div className="answer price">
                      <span>₹ {product?.price}</span>
                    </div>
                  </div>
                </div>
                <button
                  className="add-to-cart "
                  onClick={() => addToCart(product?.title, product?.id, product?.price, product?.imgUrl, product?.maxBuyable)}
                >
                  Add to cart
                </button>
              </div>
            </div>
          </section>
        );
      })}
      <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
        {productAdded && (
          <Alert severity="success" variant="filled" className="alert-position">
            <p>Product added to cart</p>
          </Alert>
        )}
      </div>
      <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
        {productOverAddHandler && (
          <Alert severity="error" variant="filled" className="alert-position">
            <p>You have added maximum quantity</p>
          </Alert>
        )}
      </div>
    </section>
  );
}

export default Description;
