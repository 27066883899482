import { createSlice } from "@reduxjs/toolkit";

const descriptionSlice = createSlice({
  name: "description",
  initialState: {
    productDetails: localStorage.getItem("description") ? JSON.parse(localStorage.getItem("description")) : [],
  },
  reducers: {
    showDescription(state, action) {
      let data = [action?.payload];
      state.productDetails = data;
      localStorage.setItem("description", JSON.stringify(data));
    },
  },
});
export const descriptionActions = descriptionSlice?.actions;
export default descriptionSlice;
