import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import { useNavigate } from "react-router-dom";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import "../Assets/Styles/layouts/account.scss";
import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
const axios = require("axios");
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function Account() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [uData, setUdata] = useState([]);
  const [value, setValue] = React.useState(0);
  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    // eslint-disable-next-line no-restricted-globals
    location.reload();
    navigate("/");
  };
  useEffect(() => {
    const getData = async () => {
      let token = localStorage.getItem("accessToken");
      let accessToken = token ? JSON.parse(token) : navigate("/");
      const options = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };
      try {
        const url = await axios.get("https://rahulcodes.dev/api/v1/users/account", options);
        const myData = [url.data.data];
        setUdata(myData);
        setLoading(true);
      } catch (error) {
        console.log(error.message);
        setLoading(true);
        // setFormError(error?.response?.data?.message);
        // console.log(error?.response?.data?.message);
      }
    };
    getData();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <React.Fragment>
      <div className="back" style={{ width: "70%", marginTop: "2.5%" }}>
        <Link to="/">
          <p>Go back</p>
        </Link>
      </div>
      <section className="account">
        <Box sx={{ maxWidth: { xs: 320, sm: "100%" }, bgcolor: "background.paper" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Tab label="Profile" {...a11yProps(0)} sx={{ fontSize: "1.3rem" }} />
              <Tab label="Orders" {...a11yProps(1)} sx={{ fontSize: "1.3rem" }} />
              <Tab label="Addresses" {...a11yProps(2)} sx={{ fontSize: "1.3rem" }} />
              <Tab label="Logout" {...a11yProps(3)} sx={{ fontSize: "1.3rem" }} onClick={handleLogout} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0} sx={{ pb: 2, fontSize: 50 }}>
            {!loading && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "5rem auto",
                }}
              >
                <div>
                  <span className="loader"></span>
                  <h2>Fetching data...</h2>
                </div>
              </div>
            )}
            {uData?.map((user) => {
              return (
                <>
                  <section className="userData" key={user.email}>
                    <div>
                      <p>Name: {user.name}</p>
                    </div>
                    <div>
                      <p>
                        Email: <span> {user.email}</span>
                      </p>
                    </div>
                  </section>
                </>
              );
            })}
          </TabPanel>
          <TabPanel value={value} index={1}>
            <h2>No orders</h2>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <button>Add address</button>
          </TabPanel>
          <TabPanel value={value} index={3}>
            ...
          </TabPanel>
        </Box>
      </section>
    </React.Fragment>
  );
}
