import { createSlice } from "@reduxjs/toolkit";

const cartSlice = createSlice({
  name: "cart",
  initialState: {
    itemList: localStorage.getItem("myCart") ? JSON.parse(localStorage.getItem("myCart")) : [],
    totalQuantity: localStorage.getItem("totalQuantity") ? JSON.parse(localStorage.getItem("totalQuantity")) : 0,
    totalPrice: localStorage.getItem("totalPrice") ? JSON.parse(localStorage.getItem("totalPrice")) : 0,
  },

  reducers: {
    addProdToCart(state, action) {
      const newItem = action.payload;
      const existingItem = state.itemList.find((item) => item.id === newItem.id);
      if (existingItem) {
        existingItem.quantity++;
        existingItem.totalPrice += newItem.price;
      } else {
        state.itemList.push({
          id: newItem.id,
          price: newItem.price,
          quantity: 1,
          itemQuantity: newItem.itemQuantity,
          imgUrl: newItem.imgUrl,
          totalPrice: newItem.price,
          title: newItem.title,
        });
        state.totalQuantity++;
        // state.totalAmount += nItem.price;
      }
      localStorage.setItem("myCart", JSON.stringify(state.itemList));
      localStorage.setItem("totalQuantity", JSON.stringify(state.totalQuantity));
      localStorage.setItem("totalPrice", JSON.stringify(state.totalPrice));
    },
    decrementFromCart(state, action) {
      const id = action.payload;
      const existingItem = state.itemList.find((item) => item.id === id);
      // if (existingItem.quantity <= 0) return;
      if (existingItem.quantity === 1) {
        state.itemList = state.itemList.filter((item) => item.id !== id);
        state.totalQuantity--;
        existingItem.totalPrice = 0;
      } else {
        existingItem.quantity--;
        existingItem.totalPrice -= existingItem.price;
      }
      localStorage.setItem("totalPrice", JSON.stringify(state.totalPrice));
      localStorage.setItem("myCart", JSON.stringify(state.itemList));
      localStorage.setItem("totalQuantity", JSON.stringify(state.totalQuantity));
    },
  },
});
export const cartActions = cartSlice.actions;
export default cartSlice;
