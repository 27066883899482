import { configureStore } from "@reduxjs/toolkit";
import cartSlice from "./CartSlice";
import descriptionSlice from "./DescriptionSlice";

export const store = configureStore({
  reducer: {
    cart: cartSlice.reducer,
    description: descriptionSlice.reducer,
  },
});
