import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { Container, Typography, Box, Alert, Grid, TextField, CssBaseline, Avatar, Button } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Link } from "react-router-dom";
import { signUpSchema } from "../schema";
import { Helmet } from "react-helmet";
const axios = require("axios");

function Copyright(props) {
  return <Typography variant="body2" color="text.secondary" align="center" {...props}></Typography>;
}

const theme = createTheme({
  typography: {
    fontSize: 20,
  },
});
const initialValues = {
  name: "",
  email: "",
  password: "",
  Confirm_Password: "",
};

export default function SignUp() {
  const navigate = useNavigate();
  const [errorsOnSubmits, setErrorsOnSubmits] = useState("");
  const [submitSuccess, setSubmitSuccess] = useState("");
  const [accountCreating, setAccountCreating] = useState(false);
  const { values, errors, handleBlur, touched, handleSubmit, handleChange } = useFormik({
    initialValues,
    validationSchema: signUpSchema,
    onSubmit: (values, action) => {
      handleRegistriation();
      setAccountCreating(true);
      action.resetForm();
    },
  });
  useEffect(() => {
    setTimeout(() => {
      setErrorsOnSubmits("");
    }, 2000);
  }, [errorsOnSubmits]);
  const navigateToHome = () => {
    setTimeout(() => {}, 2000);
    navigate("/");
    // eslint-disable-next-line no-restricted-globals
    location.reload();
  };
  const handleRegistriation = async (e) => {
    const uData = {
      name: values.name,
      email: values.email,
      password: values.password,
      confirmPassword: values.Confirm_Password,
    };
    try {
      const url = await axios({
        method: "POST",
        url: "https://rahulcodes.dev/api/v1/users/signup",
        data: uData,
        credentials: "include",
      });
      setSubmitSuccess(url.data.message);
      localStorage.setItem("accessToken", JSON.stringify(url.data.token));
      if (url.status === 201) {
        navigateToHome();
      }
      setAccountCreating(false);
    } catch (error) {
      // setFormError(error?.response?.data?.message);
      setAccountCreating(false);
      setErrorsOnSubmits(error?.response?.data?.message);
      // console.log(error?.response?.data?.message);
    }
  };
  const alertStyle = {
    display: "flex",
    m: 0,
    alignItems: "center",
    px: 2,
    py: 0.2,
    fontSize: 14,
    textAlign: "left",
    fontWeight: 500,
    mt: 1,
  };
  return (
    <section>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Sign UP</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      {accountCreating && (
        <div className="containerSpinner">
          <div className="loaderWrapper">
            <p className="loader"></p>
            <h2>Creating your account</h2>
          </div>
        </div>
      )}
      {/* <div className={formError ? "alertBox" : "alertBox disable"}> */}
      {/* </div> */}
      <ThemeProvider theme={theme}>
        {" "}
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>{/* <LockOutlinedIcon /> */}</Avatar>
            <Typography component="h1" variant="h5">
              Create Account
            </Typography>

            <section>
              {errorsOnSubmits && (
                <Alert severity="error" sx={{ px: 5 }} variant="filled" className="alert-position">
                  {errorsOnSubmits}
                </Alert>
              )}

              {submitSuccess && (
                <Alert severity="success" variant="filled" className="alert-position">
                  {submitSuccess}
                </Alert>
              )}

              <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      autoComplete="given-name"
                      name="name"
                      fullWidth
                      id="name"
                      label="First Name"
                      autoFocus
                      required
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.name && touched.name ? (
                      <Alert severity="error" className="signupAlert" sx={alertStyle}>
                        {errors.name}
                      </Alert>
                    ) : null}
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      id="email"
                      label="Email Address"
                      name="email"
                      autoComplete="email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.email && touched.email ? (
                      <Alert severity="error" className="signupAlert" sx={alertStyle}>
                        {errors.email}
                      </Alert>
                    ) : null}
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      name="password"
                      label="Password"
                      type="password"
                      id="password"
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.password && touched.password ? (
                      <Alert severity="error" className="signupAlert" sx={alertStyle}>
                        {errors.password}
                      </Alert>
                    ) : null}
                  </Grid>{" "}
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      name="Confirm_Password"
                      label="Confirm Password"
                      type="Confirm_Password"
                      id="Confirm_Password"
                      value={values.Confirm_Password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.Confirm_Password && touched.Confirm_Password ? (
                      <Alert severity="error" className="signupAlert" sx={alertStyle}>
                        {errors.Confirm_Password}
                      </Alert>
                    ) : null}
                  </Grid>
                </Grid>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 3, py: 1.25, alignItems: "center", display: "flex" }}
                >
                  Sign Up
                </Button>
                <Grid container justifyContent="flex-end">
                  <Grid item>
                    <Link to={"/"} variant="body2">
                      <span>Already have an account? Sign in</span>
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </section>
          </Box>
          <Copyright sx={{ mt: 5 }} />
        </Container>
      </ThemeProvider>{" "}
    </section>
  );
}
