import React from "react";
import DiaryBread from "../Assets/Images/cats/bread.png";
import snacksMunchies from "../Assets/Images/cats/snacks.png";
import Flour from "../Assets/Images/cats/flours.png";
import skincare from "../Assets/Images/cats/skincare.png";
import officeSupplies from "../Assets/Images/cats/office-supplies.png";
import IceCream from "../Assets/Images/cats/ice-cream.png";
import cleaning from "../Assets/Images/cats/cleaning.png";
import noodles from "../Assets/Images/cats/noodles.png";
import fruitsVeg from "../Assets/Images/cats/healthy-food.png";
import "../Assets/Styles/layouts/featuredCategories.scss";
import { Link } from "react-router-dom";
// import promo1 from "../Assets/Images/promo/promo-1.png";
// import promo2 from "../Assets/Images/promo/promo-2.png";

export default function FeaturedCategories() {
  return (
    <section className="featured-cat-container ">
      <span className="headings ">Featured categories</span>
      <div className="category-section u-margin-bottom-large">
        <div className="category-container">
          <Link to={"/all-products"}>
            <figure className="category">
              <img src={fruitsVeg} alt="Fruits-and-vegetables" />
              <figcaption>Fruits and vegetables</figcaption>
            </figure>{" "}
          </Link>
        </div>
        <div className="category-container">
          {" "}
          <Link to={"/all-products"}>
            <figure className="category">
              <img src={DiaryBread} alt="Fruits-and-vegetables" />
              <figcaption>Diary and Bread</figcaption>
            </figure>
          </Link>
        </div>
        <div className="category-container">
          {" "}
          <Link to={"/all-products"}>
            <figure className="category">
              <img src={snacksMunchies} alt={snacksMunchies} />
              <figcaption>Snacks and Chocolates</figcaption>
            </figure>
          </Link>
        </div>
        <div className="category-container">
          {" "}
          <Link to={"/all-products"}>
            <figure className="category">
              <img src={IceCream} alt={IceCream} />
              <figcaption>Ice creams</figcaption>
            </figure>
          </Link>
        </div>
        <div className="category-container">
          {" "}
          <Link to={"/all-products"}>
            <figure className="category">
              <img src={Flour} alt={Flour} />
              <figcaption>Flour, Rice & Dal</figcaption>
            </figure>
          </Link>
        </div>
        <div className="category-container">
          {" "}
          <Link to={"/all-products"}>
            <figure className="category">
              <img src={noodles} alt={noodles} />
              <figcaption>Fast foods</figcaption>
            </figure>
          </Link>
        </div>
        <div className="category-container">
          {" "}
          <Link to={"/all-products"}>
            <figure className="category">
              <img src={skincare} alt={skincare} />
              <figcaption>Personal Care</figcaption>
            </figure>
          </Link>
        </div>{" "}
        <div className="category-container">
          {" "}
          <Link to={"/all-products"}>
            <figure className="category">
              <img src={officeSupplies} alt={officeSupplies} />
              <figcaption>Home and office</figcaption>
            </figure>
          </Link>
        </div>
        <div className="category-container">
          {" "}
          <Link to={"/all-products"}>
            <figure className="category">
              <img src={cleaning} alt={cleaning} />
              <figcaption>Cleaning Essentials</figcaption>
            </figure>
          </Link>
        </div>
      </div>

      {/* <section className="promo-container  u-margin-bottom-large">
        <div className="promo ">
          <a href="/organicVegetables">
            {" "}
            <img src={promo1} alt="Fruits and vegie" />
          </a>
        </div>
        <div className="promo">
          <a href="/freshCoffee">
            <img src={promo2} alt="Fruits and vegie" />
          </a>
        </div>
        <div className="promo">
          <a href="/freshCoffee">
            <img src={promo1} alt="Fruits and vegie" />
          </a>
        </div>
      </section> */}
    </section>
  );
}
