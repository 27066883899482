import React from "react";

function Payments() {
  return (
    <div className="mainWrapper" style={{ margin: "5% auto", fontSize: "1.5rem" }}>
      <h1>Payments soon..</h1>
    </div>
  );
}

export default Payments;
