import React, { useState, useEffect } from "react";
import emptyCart from "../Assets/Images/core/empty-cart.webp";
import formatAmount from "indian-currency-formatter";
import { useDispatch, useSelector } from "react-redux";
import { cartActions } from "../Store/CartSlice";
import { decrementItems } from "../Services/Actions/DecrementItems";
import items from "../Data/Products.json";
import "../Assets/Styles/layouts/cart.scss";
import { Link } from "react-router-dom";
import { Alert } from "@mui/material";

import { Helmet } from "react-helmet";
function MyCart() {
  const uniqueItemsInCart = useSelector((state) => state.cart.totalQuantity);
  const inCart = useSelector((state) => state.cart.itemList);
  const isEmpty = 0;
  const [addProduct, updateaddProduct] = useState(false);
  const [overAddProductHandler, updateoverAddProductHandler] = useState(false);

  const dispatch = useDispatch();
  let total = 0;
  inCart.forEach((element) => {
    total += element.totalPrice;
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      updateaddProduct(false);
      updateoverAddProductHandler(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, [addProduct, overAddProductHandler]);

  const addToCart = (title, id, price, imgUrl, maxBuyable, itemQuantity) => {
    const hash = {};
    items?.map((el, key) => el.itemList.filter((item) => (item.id === id ? (maxBuyable = item.maxBuyable) : 0)));
    inCart?.map((item) => (item.id === id && item.quantity >= maxBuyable ? (hash[id] = true) : (hash[maxBuyable] = false)));
    if (hash[id] === true) {
      updateoverAddProductHandler(true);
    } else {
      dispatch(
        cartActions?.addProdToCart({
          title,
          id,
          price,
          imgUrl,
          itemQuantity,
        })
      );
      updateaddProduct(true);
    }
    const order = {
      orderDetails: {
        customerName: "Rahul",
        customerMobile: 78987987,
        products: inCart,
        customerAddress: "Lane 23",
        deliveryTime: "unknown",
        paymentStatus: "pending",
        couponCode: null,
        orderIntiatedAt: new Date(),
      },
    };

    // console.log(order);
  };

  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Sign UP</title>
        <meta name="description" content="Free Web tutoriaewfwefwels" />
        <meta name="keywords" content="HTML, CSS, JavaWEFWEFScript" />
        <meta name="author" content="John DWEFWEFoe" />
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <div className="back" style={{ width: "95%" }}>
        <Link to="/">
          <p>Go back</p>
        </Link>
      </div>
      <section className="cart">
        <div className="main">
          <div className="cart-container">
            <div className="cart-header">
              <p>My cart</p>
            </div>
            <span className="seprator"></span>
            <div className="cart-body">
              <div className={uniqueItemsInCart > 0 ? "empty-cart-img disable" : "empty-cart-img"}>
                {isEmpty === 0 ? (
                  <img src="https://gromart-resources.s3.ap-south-1.amazonaws.com/Images/core/empty-cart.webp" alt={emptyCart} />
                ) : null}
                {uniqueItemsInCart <= 0 ? <p className="empty-cart">Your cart is empty!</p> : null}
              </div>
              {inCart?.map((item, key) => (
                <div key={key} className="product-in-cart ">
                  <div className="img-container">
                    <img src={item.imgUrl} alt={item.title} />
                  </div>

                  <div className="details">
                    <p className="title">{item.title}</p>
                    <p className="quant">{item.itemQuantity}</p>
                    {/* {item.quantity > 1 ? " units" : " unit"} x ₹ {item.price} */}
                    <div className="btn-and-price">
                      <p className="price"> ₹{item.price}</p>
                      <div className="product-price-with-btn-cart">
                        <div className="incr-decr-product ">
                          <button className="incr-decr-btn" onClick={() => decrementItems(item.id, dispatch)}>
                            -
                          </button>
                          <span className="incr-decr-btn">{item.quantity} </span>
                          <button
                            className="incr-decr-btn"
                            onClick={() => addToCart(item.title, item.id, item.price, item.imgUrl)}
                          >
                            +
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className={isEmpty ? "disable" : "pricing-container"}>
            <div className="pricing-header">
              <p>PRICE DETAILS</p>
            </div>
            <span className="seprator"></span>
            <div className="pricing-body">
              {" "}
              <div className="container-price">
                <p>Price ({uniqueItemsInCart} items)</p>
                <p> ₹ {formatAmount(total)} </p>
              </div>
              <div className="container-price ">
                <p>Discount</p>
                <p className="green"> 5%</p>
              </div>
              <div className="container-price ">
                <p>Delivery Charges</p>
                <p className="green">FREE</p>
              </div>
              <span className="dot-seprator u-margin-top-m"></span>
              <div className="container-price total u-margin-top-sm">
                <p>Total Amount</p>
                <p>₹ {formatAmount(total - total * 0.05)}</p>
              </div>
              <span className="dot-seprator u-margin-top-sm"></span>
              <div className="container-price save u-margin-top-sm ">
                <p>You will save ₹{(total - (total - total * 0.05)).toFixed(2)} on this order</p>
              </div>
            </div>{" "}
            <form className="checkout-container" action="./payment" method="post">
              <a href="./payment" className="checkout-link-btn-container">
                Proceed to payement
              </a>
            </form>
          </div>
        </div>{" "}
        <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
          {addProduct && (
            <Alert severity="success" variant="filled" className="alert-position">
              <p>Product added to cart</p>
            </Alert>
          )}
        </div>
        <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
          {overAddProductHandler && (
            <Alert severity="error" variant="filled" className="alert-position">
              <p>You have added maximum quantity</p>
            </Alert>
          )}
        </div>
      </section>{" "}
    </React.Fragment>
  );
}
export default MyCart;
