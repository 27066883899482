import React, { useEffect, useState } from "react";
import "../Assets/Styles/layouts/products.scss";
import { useDispatch, useSelector } from "react-redux";
import { cartActions } from "../Store/CartSlice";
import { descriptionActions } from "../Store/DescriptionSlice";
import { decrementItems } from "../Services/Actions/DecrementItems";
import { Link } from "react-router-dom";
import { Alert } from "@mui/material";
// import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import items from "../Data/Products.json";
function ProductsC() {
  const dispatch = useDispatch();
  const inCart = useSelector((state) => state.cart.itemList);
  const [productAdded, updateproductAdded] = useState(false);
  const [products, updateproducts] = useState(items);
  const [productOverAddHandler, updateproductOverAddHandler] = useState(false);
  const [isLoading, updateisLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      updateproductAdded(false);
      updateproductOverAddHandler(false);
      updateisLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, [productAdded, productOverAddHandler, isLoading]);

  class ProductCache {
    constructor() {
      this.cache = new Map();
    }

    get(key) {
      let item = this.cache.get(key);
      if (item) {
        this.cache.delete(key);
        this.cache.set(key, item);
      }
      return item;
    }
    set(key, value) {
      if (this.cache.has(key)) this.cache.delete(key);
      this.cache.set(key, value);
    }
  }

  const productListing = new ProductCache();
  productListing.set("products", products);

  const addToCart = (title, id, price, imgUrl, maxBuyable, itemQuantity) => {
    const hash = {};
    inCart?.map((item) => (item.id === id && item.quantity >= maxBuyable ? (hash[id] = true) : (hash[maxBuyable] = false)));
    if (hash[id] === true) return updateproductOverAddHandler(true);
    else {
      updateproductAdded(true);
      dispatch(
        cartActions?.addProdToCart({
          title,
          id,
          price,
          imgUrl,
          itemQuantity,
        })
      );
    }
  };
  const productDescrHandler = (product) => {
    dispatch(descriptionActions?.showDescription(product));
  };
  return (
    <section>
      {productListing.cache.get("products").map((category, key) => (
        <section className="products-container-main " key={key}>
          <div className="category-wrapper">
            <p className="category-name">{category.categoryName}</p>
            <Link to={"/all-products"} title="View all products">
              View all
            </Link>
          </div>

          <div className="products u-margin-bottom-medium">
            {/* prettier-ignore */}
            <Splide options={{ rewind: true,perMove: 1,perPage: 3,pauseOnFocus: true,drag: "false",autoWidth: true,pagination: false,
            dragMinThreshold: {
                  mouse: 25,touch: 20},
                breakpoints: {
                  700: { arrows: false,perMove: 1,perPage: 1,
                  },
                },
              }}
            >
              {category?.itemList?.map((product) => (
               <SplideSlide key={product.id} className={isLoading === false ? "product-container u-margin-top-mini" :"product-container u-margin-top-mini disable" }>
              
                  <Link to="/description" onClick={() => productDescrHandler(product)}>
                    <div className="product-image">
                      <img title={product.title} alt={product.title} src={product.imgUrl} />
                    </div>
                    <div className="product-name">
                      <p className="product-name-txt">
                        {product.title.length >= 28 && window.innerWidth <= 1400
                          ? product.title.slice(0, 28) + "..."
                          : product.title}
                      </p>
                    </div>
                    <div className="product-detail">
                      <p className="product-detail-txt">{product.itemQuantity}</p>
                    </div>
                  </Link>
                  <div className="product-price-with-btn">
                    <p className="product-price">₹ {product.price}</p>
                    {inCart?.find((item) => item.id === product.id) ? (
                      <div className="incr-decr-product">
                        <button className="incr-decr-btn" onClick={() => decrementItems(product.id, dispatch)}>
                          -
                        </button>
                        {inCart?.map((item, key) =>
                          item.id === product.id ? (
                            <button className="incr-decr-btn" key={key}>
                              {item.quantity}
                            </button>
                          ) : null
                        )}
                        <button
                          className="incr-decr-btn"
                          onClick={() => addToCart(product.title, product.id, product.price, product.imgUrl, product.maxBuyable)}
                        >
                          +
                        </button>
                      </div>
                    ) : (
                      <button
                        title="Add to cart"
                        onClick={() =>
                          addToCart(
                            product.title,
                            product.id,
                            product.price,
                            product.imgUrl,
                            product.maxBuyable,
                            product.itemQuantity
                          )
                        }
                        className="add-to-cart"
                      >
                        ADD
                      </button>
                    )}
                  </div>
                </SplideSlide>
              ))}
            </Splide>
          </div>
        </section>
      ))}

      <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
        {productAdded && (
          <Alert severity="success" variant="filled" className="alert-position">
            <p>Product added to cart</p>
          </Alert>
        )}
      </div>
      <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
        {productOverAddHandler && (
          <Alert severity="error" variant="filled" className="alert-position">
            <p>You have added maximum quantity</p>
          </Alert>
        )}
      </div>
    </section>
  );
}

export default ProductsC;
