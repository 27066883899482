import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.scss";
import { Provider } from "react-redux";
import { store } from "./Store/store";
import HeaderLogic from "./Components/HeaderLogic";
import Cart from "./Components/Cart";
import Payments from "./Components/Payments";
import Signup from "./Components/Signup";
import Login from "./Components/Login";
import Products from "./Components/Products";
import Description from "./Components/Description";
import Slider from "./Components/Slider";
import FeaturedCategories from "./Components/FeaturedCategories";
import Account from "./Components/Account";
import Footer from "./Components/Footer";
import { Helmet } from "react-helmet";
import AllProducts from "./Components/AllProducts";
function App() {
  const [loginState, setLoginState] = useState(true);
  let token = localStorage.getItem("accessToken");
  useEffect(() => {
    token ? setLoginState(false) : setLoginState(true);
  }, [token]);
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <div className="mainWrapper">
                <Helmet>
                  <meta charSet="utf-8" />
                  <title>Sign UP</title>
                  <meta name="description" content="Free Web tutorials" />
                  <meta name="keywords" content="HTML, CSS, JavaScript" />
                  <meta name="author" content="John Doe" />
                  <link rel="canonical" href="http://mysite.com/example" />
                </Helmet>
                <HeaderLogic searchBar={true} getLocation={true} login={loginState} cart={true} />
                <Slider />
                <FeaturedCategories />
                <Products />
                <Footer />
              </div>
            }
          ></Route>
          <Route
            path="/cart"
            element={
              <>
                <HeaderLogic searchBar={false} getLocation={true} login={loginState} cart={false} noJustify="true" />
                <Cart />
              </>
            }
          ></Route>
          <Route
            path="/description"
            element={
              <>
                <HeaderLogic searchBar={true} getLocation={true} login={loginState} cart={true} />
                <Description />
              </>
            }
          ></Route>
          <Route path="/payment" element={<Payments />}></Route>
          <Route path="/signup" element={<Signup />}></Route>
          <Route path="/login" element={<Login />}></Route>
          <Route
            path="/account"
            element={
              <>
                <HeaderLogic searchBar={true} getLocation={true} login={loginState} cart={true} />
                <Account />
              </>
            }
          ></Route>
          <Route
            path="/all-products"
            element={
              <React.Fragment>
                <HeaderLogic searchBar={true} getLocation={true} login={loginState} cart={true} />
                <AllProducts />
              </React.Fragment>
            }
          ></Route>
        </Routes>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
