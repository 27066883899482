import React from "react";
import "../Assets/Styles/layouts/slider.scss";
// import slider from "../Assets/Images/slider/slide1.";

export default function Slider() {
  return (
    <section className="slide-container u-margin-bottom-medium ">
      <div className="slides-container">
        <img
          className="slides"
          src="https://gromart-resources.s3.ap-south-1.amazonaws.com/Images/slider/slide1.webp"
          alt="Slider-1"
        />
      </div>
    </section>
  );
}
