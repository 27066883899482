import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cartActions } from "../Store/CartSlice";
import { descriptionActions } from "../Store/DescriptionSlice";
import { decrementItems } from "../Services/Actions/DecrementItems";
import PropTypes from "prop-types";
import { Tab, Tabs, Box } from "@mui/material";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import items from "../Data/Products.json";
import "../Assets/Styles/layouts/allProducts.scss";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <>{children}</>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function AllProducts() {
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const sortedProducts = [];
  items.map((cate) => {
    return sortedProducts.push(cate.itemList);
    // cate.itemList.map((a) => x.push(a));
  });

  const inCart = useSelector((state) => state.cart.itemList);
  const [productAdded, updateproductAdded] = useState(false);
  const [productOverAddHandler, updateproductOverAddHandler] = useState(false);
  const dispatch = useDispatch();
  const [isLoading, updateisLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      updateproductAdded(false);
      updateproductOverAddHandler(false);
      updateisLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, [productAdded, productOverAddHandler, isLoading]);

  const addToCart = (title, id, price, imgUrl, maxBuyable, itemQuantity) => {
    const hash = {};
    inCart?.map((item) => (item.id === id && item.quantity >= maxBuyable ? (hash[id] = true) : (hash[maxBuyable] = false)));
    if (hash[id] === true) return updateproductOverAddHandler(true);
    else {
      updateproductAdded(true);
      dispatch(
        cartActions?.addProdToCart({
          title,
          id,
          price,
          imgUrl,
          itemQuantity,
        })
      );
    }
  };
  const productDescrHandler = (product) => {
    dispatch(descriptionActions?.showDescription(product));
  };
  return (
    <React.Fragment>
      <div className="all-products-wrap">
        <div className="fix-section"></div>
        <Box sx={{ flexGrow: 1, bgcolor: "background.paper", display: "flex", height: 500 }}>
          <Box sx={{ position: "fixed" }}>
            {" "}
            <div className="back" onClick={() => navigate(-1)} style={{ width: "88%" }}>
              <p>Go back</p>
            </div>
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={value}
              allowScrollButtonsMobile
              scrollButtons="auto"
              onChange={handleChange}
              aria-label="horizontal tabs"
              className="tabs-container"
              sx={{ display: "flex", justifyContent: "space-between", zIndex: 2 }}
            >
              {items?.map((category, i) => {
                return <Tab className="tab-label" label={category.categoryName} {...a11yProps(i)} key={category.categoryName} />;
              })}
            </Tabs>
          </Box>
          <section className="products-container-wrapper">
            {sortedProducts.map((item, idx) => {
              return item.map((product) => {
                return (
                  <TabPanel value={value} index={idx} key={product.id} className="product-container ">
                    <div className="product-wrapper">
                      <Link to="/description" onClick={() => productDescrHandler(product)}>
                        <div className="product-image">
                          <img title={product.title} alt={product.title} src={product.imgUrl} />
                        </div>
                        <div className="product-name">
                          <p className="product-name-txt">
                            {product.title.length >= 28 && window.innerWidth <= 1400
                              ? product.title.slice(0, 28) + "..."
                              : product.title}
                          </p>
                        </div>
                        <div className="product-detail">
                          <p className="product-detail-txt">{product.itemQuantity}</p>
                        </div>{" "}
                      </Link>
                      <div className="product-price-with-btn">
                        <p className="product-price">₹ {product.price}</p>
                        {inCart?.find((item) => item.id === product.id) ? (
                          <div className="incr-decr-product">
                            <button className="incr-decr-btn" onClick={() => decrementItems(product.id, dispatch)}>
                              -
                            </button>
                            {inCart?.map((item, key) =>
                              item.id === product.id ? (
                                <button className="incr-decr-btn" key={key}>
                                  {item.quantity}
                                </button>
                              ) : null
                            )}
                            <button
                              className="incr-decr-btn"
                              onClick={() =>
                                addToCart(product.title, product.id, product.price, product.imgUrl, product.maxBuyable)
                              }
                            >
                              +
                            </button>
                          </div>
                        ) : (
                          <button
                            title="Add to cart"
                            onClick={() =>
                              addToCart(
                                product.title,
                                product.id,
                                product.price,
                                product.imgUrl,
                                product.maxBuyable,
                                product.itemQuantity
                              )
                            }
                            className="add-to-cart"
                          >
                            ADD
                          </button>
                        )}
                      </div>{" "}
                    </div>
                  </TabPanel>
                );
              });
            })}
          </section>
        </Box>
      </div>
    </React.Fragment>
  );
}
