import * as React from "react";
import { useFormik } from "formik";
import { Container, Typography, Box, Alert, FormControlLabel, Grid, TextField, CssBaseline, Avatar, Button } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Signup from "./Signup";
import { Link } from "react-router-dom";
import { loginSchema } from "../schema";
import { useState } from "react";
import { useEffect } from "react";
const axios = require("axios");
function Copyright(props) {
  return <Typography variant="body2" color="text.secondary" align="center" {...props}></Typography>;
}
const theme = createTheme({
  typography: {
    fontFamily: `"Source Sans Pro", "Inter", "Poppins", sans-serif`,
    fontSize: 22,
  },
});
const initialValues = {
  email: "",
  password: "",
};
export default function SignIn() {
  const [accountCreating, setAccountCreating] = useState(false);
  const [LoginErr, updateLoginErr] = useState("");
  const [submitSuccess, setSubmitSuccess] = useState("");
  const { values, errors, handleBlur, handleChange, touched, handleSubmit } = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, action) => {
      loginRequest();
      setAccountCreating(true);
      action.resetForm();
    },
  });
  useEffect(() => {
    setTimeout(() => {
      updateLoginErr("");
    }, 2000);
  }, [LoginErr]);
  const loginRequest = async (event) => {
    try {
      const userData = {
        email: values.email,
        password: values.password,
      };
      const options = {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": false,
          "Content-Type": "application/json",
        },
        email: userData.email,
        password: userData.password,
      };
      const url = await axios.post("https://rahulcodes.dev/api/v1/users/login", options);
      setAccountCreating(false);
      const userToken = localStorage.setItem("accessToken", JSON.stringify(url.data.accessToken));

      if (url.status === 200) setSubmitSuccess("Login success!");
      window.location.reload();
      // console.log(res);
    } catch (error) {
      setAccountCreating(false);
      updateLoginErr(error?.response?.data?.message);
      // console.log(error.response.data.message);
    }
  };

  return (
    <React.Fragment>
      {accountCreating && (
        <div className="containerSpinner">
          <div className="loaderWrapper">
            <p className="loader"></p>
            <h2>Signing in..</h2>
          </div>
        </div>
      )}
      {LoginErr && (
        <Alert severity="error" sx={{ px: 5 }} variant="filled" className="alert-position">
          {LoginErr}
        </Alert>
      )}
      {submitSuccess && (
        <Alert severity="success" sx={{ px: 5 }} variant="filled" className="alert-position">
          <p>{submitSuccess}</p>
        </Alert>
      )}
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}></Avatar>
            <Typography component="h1" variant="h6">
              Sign in
            </Typography>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }} style={{ width: "85%" }}>
              <TextField
                margin="normal"
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                InputLabelProps={{ style: { fontSize: "2rem" } }} // font size of input label
              />

              {errors.email && touched.email ? <p className="errorAlert">{errors.email}</p> : null}
              <TextField
                margin="normal"
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                autoComplete="current-password"
                InputLabelProps={{ style: { fontSize: "2rem" } }}
              />
              {errors.password && touched.password ? <p className="errorAlert">{errors.password}</p> : null}
              {/* <FormControlLabel control={<Checkbox value="remember" color="primary" />} label="Remember me" /> */}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 3, pt: 1.5 }}
                style={{ display: "flex", alignItems: "center" }}
              >
                Sign In
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link to href="#" style={{ color: "#1976d2", fontSize: "1.5rem" }}>
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link to={"/signup"} element={<Signup />} style={{ color: "#1976d2", fontSize: "1.5rem" }}>
                    Don't have an account? Sign Up
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Copyright sx={{ mt: 8, mb: 4 }} />
        </Container>
      </ThemeProvider>
    </React.Fragment>
  );
}
